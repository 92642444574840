export let CustomNode = [
  {
    lob_code: "DEFAULT",
    attribute_key: "payment_data",
    name: "Payment Data",
    applicable_pmt: "DEFAULT",
    display_type: "form",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "application",
    name: "Application",
    applicable_pmt: "#initial#initial_combo#initial_other#",
    display_type: "list",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "policy",
    name: "Policy",
    applicable_pmt: "#renewal#renewal_combo#loan#initial_other#",
    display_type: "list",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "insured",
    name: "Insured",
    applicable_pmt: "#initial#initial_combo#initial_other#",
    display_type: "form",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "agent",
    name: "Agent",
    applicable_pmt:
      "#renewal#renewal_combo#loan#initial#initial_combo#initial_other#",
    display_type: "form",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "other_info",
    name: "Other Info",
    applicable_pmt:
      "#renewal#renewal_combo#loan#initial#initial_combo#initial_other#",
    display_type: "form",
    status: "active",
  },
  {
    lob_code: "ABSLI",
    attribute_key: "renewal",
    name: "Renewal",
    applicable_pmt:
      "renewal#renewal_combo#loan#initial#initial_combo#initial_other",
    display_type: "form",
    status: "active",
  },
];
