import dateFormat from "dateformat";
import flatpickr from "flatpickr";

export const OnChangeHandler = (e, item, data, formJson, setFormJson) => {
  switch (item.type) {
    case "text":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;
    case "floatingLabel":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "date":
      setFormJson((prev) => ({
        ...prev,
        [item.name]: dateFormat(e[0], "dd-mm-yyyy"),
      }));
      break;

    case "daterange":
      flatpickr(`${item.name}`, {
        mode: "range",
        onChange: ([start, end]) => {
          if (start && end) {
            setFormJson((prev) => ({
              ...prev,
              [item.start]: dateFormat(e[0], "dd-mm-yyyy"),
              [item.end]: dateFormat(e[1], "dd-mm-yyyy"),
            }));
          }
        },
      });

      break;

    case "textarea":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "json":
      setFormJson((prev) => ({
        ...prev,
        [item.name]: JSON.parse(e.target.value),
      }));
      break;

    case "number":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "email":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "password":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "switch":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.checked }));
      break;

    case "select":
      setFormJson((prev) => ({ ...prev, [item.name]: data.value.trim() }));
      break;

    case "customselect":
      setFormJson((prev) => ({ ...prev, [item.name]: data.value.trim() }));
      break;

    case "radio":
      setFormJson((prev) => ({ ...prev, [item.name]: e.target.value }));
      break;

    case "multiselect":
      let newArr = [];
      let aParent = "";
      let arrVal = "";
      {
        Object.entries(data).map(([key, val]) => {
          newArr.push(val.value.trim());
          aParent = val.parent;
        });
      }
      arrVal = newArr.join(",");
      setFormJson((prev) => ({ ...prev, [aParent]: arrVal }));
      break;

    default:
      break;
  }
};
