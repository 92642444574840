import dateFormat from "dateformat";

export let paymentData = {
  payment_type: "BASIC",
  return_url: "https://liuatpayments.adityabirlacapital.com/resultpage",
  request_id: Date.parse(new Date()),
  request_date: dateFormat(new Date(), "dd/mm/yyyy HH:mm:ss"),
  lob_id: "ABSLI",
  payment_source_id: "CP_PayPremium",
  amount: Math.floor(Math.random()*10000)+1,
  product_type: "POLICY",
  product_id: "POL"+Math.floor(Math.random()*1000)+1,
  customer_name: "ABSLI TEST"+Math.floor(Math.random()*1000)+1,
  customer_email: "test@absli.com",
  customer_phone: "9898989898",
  customer_id: "CST0001",
  view_type: "v1",
  udf1: "CP_PayPremium",
  udf2: "002650",
  udf3: "",
  udf4: "",
  udf5: "",
  udf6: "",
  udf7: "",
  udf8: "",
  udf9: "",
  udf10: "",
  max_mandate_amount: Math.floor(Math.random()*100000)+1,
  mandate_frequency: "ASPRESENTED",
  mandate_startdate: Date.parse(new Date()),
  mandate_enddate: ((new Date()).setFullYear(2099)),
  cust_acc_num: "50100001064120",
  cust_back_ifsc: "HDFC0000060",
  cust_beneficiary_name: "ABSLI Test Customer"+Math.floor(Math.random()*1000)+1
}
