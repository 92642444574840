export const CrossIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
      fill="#C7222A"
    />
  </svg>
);
export const ErrorSymbol = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6.49L19.53 19.5H4.47L12 6.49ZM12 2.5L1 21.5H23L12 2.5ZM13 16.5H11V18.5H13V16.5ZM13 10.5H11V14.5H13V10.5Z"
      fill="#C7222A"
    />
  </svg>
);

export const Complete =()=>{
  <svg width="48" height="47" viewBox="0 0 48 47" fill="none">
<rect width="48" height="46" transform="translate(0 0.600098)" fill="url(#pattern0)"/>
<defs>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use  transform="matrix(0.0282021 0 0 0.027027 -0.0217391 0)"/>
</pattern>
<image id="image0_1443_3485" width="37" height="37" />
</defs>
</svg>

}

