import React from "react";
import { Accordion, Row } from "react-bootstrap";
import FormRender from "../../../../../common/FormRender/FormRender";
import { CustomNode } from "../../../util/CustomNode";
import List from "./List";
import "./style.scss";

const AppData = ({ mappingData, state, setState }) => {
  let accordianJson = { "": "" };
  let data = [];
  let arr = [];

  let changeHandler = (e, itm) => {
    setState((prev) => ({
      ...prev,
      app_data: {
        ...prev.app_data,
        [itm.parent]: {
          ...prev.app_data[itm.parent],
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  const createMapping = () => {
    mappingData &&
      mappingData.map((itm) => {
        for (let k in accordianJson) {
          if (k === itm.parent_node && itm.parent_node !== "payment_data") {
            accordianJson[k].push({
              lob_code: itm.lob_code,
              parent_node: itm.parent_node,
              attribute_key: itm.attribute_key,
              data_type: itm.data_type,
              control_type: itm.control_type,
              format: itm.format,
              label: itm.label,
              payment_methods: itm.payment_methods,
              mandatory: itm.mandatory,
              seq: itm.seq,
            });
          } else if (
            !accordianJson[itm.parent_node] &&
            itm.parent_node !== "payment_data"
          ) {
            data.push(itm.entityid);
            accordianJson[itm.parent_node] = [
              {
                lob_code: itm.lob_code,
                parent_node: itm.parent_node,
                attribute_key: itm.attribute_key,
                data_type: itm.data_type,
                control_type: itm.control_type,
                format: itm.format,
                label: itm.label,
                payment_methods: itm.payment_methods,
                mandatory: itm.mandatory,
                seq: itm.seq,
              },
            ];
          }
        }
      });
  };

  let createAccordionArr = () => {
    for (let k in accordianJson) {
      arr.push({ [k]: accordianJson[k] });
    }
  };

  createMapping();
  createAccordionArr();
  return (
    <>
      <Accordion defaultActiveKey="0" alwaysOpen>
        {arr.map((el, i) => {
          let display;
          CustomNode.map((itm) => {
            if (Object.keys([el][0])[0] === itm.attribute_key)
              display = itm.display_type;
          });
          if (Object.keys([el][0])[0] !== "") {
            return (
              (display === "form" && (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>{Object.keys([el][0])[0].toUpperCase()}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {Object.values([el][0])[0].map((itm) => {
                        return (
                          <FormRender
                            key={itm.id}
                            itm={{
                              parent: Object.keys([el][0])[0],
                              name: itm.attribute_key,
                              value: "",
                              label: itm.label,
                              placeholder: " ",
                              type: "floatingLabel",
                              row: i,
                              styleClass: "col-4",
                              edit: true,
                              handler: changeHandler,
                            }}
                            edit={true}
                            formState={state}
                            setFormState={setState}
                          />
                        );
                      })}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              )) ||
              (display === "list" && (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>{Object.keys([el][0])[0].toUpperCase()}</Accordion.Header>
                  <Accordion.Body>
                    <List
                      data={Object.values([el][0])[0]}
                      state={state}
                      setState={setState}
                      parent={Object.keys([el][0])[0]}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              ))
            );
          }
        })}
      </Accordion>
    </>
  );
};

export default AppData;
