import React from "react";
import "./style.scss";

const StatusFooter = () => {
  return (
    <footer className="status-page-footer">
      <p>© 2022, Aditya Birla Capital Ltd. All Rights Reserved.</p>
      <p>
        Call us toll free : <i class="fa-solid fa-phone"></i> 1800 270 7000
      </p>
    </footer>
  );
};

export default StatusFooter;
