import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./style.scss";

const List = ({ data, state, setState, parent }) => {
  const [item, setitem] = useState({});
  const tempItm = {};

  useEffect(() => {
    data.map((e, i) => {
      tempItm[e.attribute_key] = "";
      setitem(tempItm);
    });
  }, []);

  let changeHandler = (e) => {
    setitem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  let deletItem = (ano) => {
    setState((prev) => ({
      ...prev,
      app_data: {
        ...prev.app_data,
        [parent]: prev.app_data[parent].filter((i) => i.appl_number !== ano),
      },
    }));
  };
  let addItem = (item) => {
    setState((prev) => ({
      ...prev,
      app_data: {
        ...prev.app_data,
        [parent]: [...prev.app_data[parent], item],
      },
    }));
  };
  return (
    <div class="ItemCard">
      <table class="table">
        <thead>
          <tr>
            {data.map((itm) => {
              return <th scope="col">{itm.label}</th>;
            })}
          </tr>
          <tr>
            {data.map((itm) => {
              return (
                <th scope="col">
                  <input
                    type="text"
                    name={itm.attribute_key}
                    id=""
                    class="form-control items"
                    placeholder={itm.attribute_key}
                    value={item && item[itm.attribute_key]}
                    onChange={changeHandler}
                  />
                </th>
              );
            })}
            <th scope="col">
              <button class="btn btn-lg addBtn" onClick={() => addItem(item)}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {state && state.app_data && state.app_data[parent].length <= 0 ? (
            <span>No data</span>
          ) : (
            state &&
            state.app_data &&
            state.app_data[parent].map((el, i) => {
              return (
                <tr>
                  {Object.entries(el).map(([key, val]) => {
                    return <td>{val}</td>;
                  })}
                  <td>
                    <button
                      class="btn btn-lg "
                      onClick={() => deletItem(el.appl_number)}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="red"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </span>
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default List;
