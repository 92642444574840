import React from "react";
import StatusFooter from "./StatusFooter";
import StatusHeader from "./StatusHeader";
import "./style.scss";

const HomePage = () => {
  return (
    <div className="status-container">
      <StatusHeader />
      <div className="status-contain">
        <h2 className="status-heading-pnf">
          Happiness is a Choice and so is your bright financial future!
        </h2>
        <p className="status-subHeading-pnf">
          Securing your family`s dreams is at your fingertips. ABSLI offers a
          range of plans for every stage of costumer`s life cycle Be it your
          retirement plans, child plans, endowment plans or savings plan, we`ve
          got your back! With 165+ year old legacy of Fortune 500 Aditya Birla
          Group, ABSLI thrives on credibility and reliability when it comes to
          Life Insurance. ABSLI helps empower you with financial protection and
          secures your family`s financial future.
        </p>
      </div>
      {/* <StatusFooter /> */}
    </div>
  );
};

export default HomePage;
