import React,{useEffect, useState} from "react";
import { processOrder, getRedirect } from "../service/service";
import { useParams, useNavigate } from "react-router-dom";

const ProcessOrder = () => {
    let { id } = useParams();
    let nav = useNavigate();
    let [state, setState] =  useState({});
    let [trigger, setTrigger] =  useState(false);

	useEffect(() => {
	    processOrder(id, state, setState,setTrigger,false);
	},[]);

    return(
    <div>
        <p>{state && state.status}</p>
    </div>

    )};

export default ProcessOrder;