import React from "react";
import Header from "../header/Header";
import StatusFooter from "./StatusFooter";
import StatusHeader from "./StatusHeader";

const PageNotFound = () => {
  return (
    <>
      <div className="status-container">
        <StatusHeader />
        <div className="status-contain">
          <div></div>
          <h2 className="status-heading-pnf">
            We regret to inform you that the requested URL was not found on the
            server.
          </h2>
          <p className="status-subHeading-pnf">
            Check your network and try again or check for any spelling error. If
            the error persists, then there`s something better waiting for you on
            the same platform!
            {/* <a className="status-subHeading-pnf-link" href="/">
              Homepage
            </a> */}
            {/* &nbsp;or try again after sometime. */}
          </p>
        </div>
        {/* <StatusFooter /> */}
      </div>
    </>
  );
};

export default PageNotFound;
