import React from "react";
import Logo from "../../assets/img/ab_logo.png";

const StatusHeader = () => {
  return (
    <div className="error-page-header">
      <img src={Logo} alt="" className="status-page-header-logo"/>
   
    </div>
  );
};

export default StatusHeader;
