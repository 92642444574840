import React, { useState } from "react";
import "./style.scss";
import Header from "../../../../common/header/Header";
import { Nav, Form, Row } from "react-bootstrap";
import { Createtranx, getAllField, getAllNode } from "../../service/Service";
import FormRender from "../../../../common/FormRender/FormRender";
import { CustomForm } from "../../util/CustomForm";
import { useEffect } from "react";
import { paymentData } from "../../util/basicState";
import AppData from "./AppData/AppData";
import StatusFooter from "../../../../common/StatusPages/StatusFooter";

const TestPage = () => {
  let [redirect, setRedirect] = useState(true);
  let [resp, setResp] = useState(null);
  let [state, setState] = useState({});
  let [viewType, setViewType] = useState("basic");
  let payment_data = {};
  let [node, setNode] = useState([]);
  let [field, setField] = useState([]);
  let [lob_code, setLob_code] = useState("ABSLI");
  let [payment_methods, setPayment_methods] = useState("initial");
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    getAllNode(lob_code, setNode, trigger, setTrigger);
    getAllField(lob_code, payment_methods, setField, trigger, setTrigger);
  }, []);

  useEffect(() => {
    field &&
      field.map((formelement, i) => {
        let nodeVal = {};
        node.map((el) => {
          if (
            el.display_type === "list" &&
            el.attribute_key === formelement.parent_node
          )
            nodeVal = [];
        });
        if (formelement.parent_node === "payment_data") {
          payment_data[formelement.attribute_key] =
          paymentData[formelement.attribute_key] || "";
          setState((prev) => ({
            ...prev,
            payment_data: payment_data,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            app_data: {
              ...prev.app_data,
              [formelement.parent_node]: nodeVal,
            },
          }));
        }
      });

    setState((prev) => ({
      ...prev,
      payment_data: {
        ...prev.payment_data,
        payment_type: payment_methods.toLocaleUpperCase().replace("_", " "),
      },
    }));
  }, [trigger]);

  let paymentDataHandler = (e, itm) => {
    setState((prev) => ({
      ...prev,
      payment_data: {
        ...prev.payment_data,
        [itm.name]: e.target.value,
      },
    }));
  };

  let handleSubmit = () => {
    Createtranx(state, setState, resp, setResp, redirect);
  };

  let onChange = (lob_code, payment_methods) => {
    setState({});
    getAllNode(lob_code, setNode, trigger, setTrigger);
    getAllField(lob_code, payment_methods, setField, trigger, setTrigger);
  };

  return (
    <>
      <Header title={true} />
      <div className="contain-wrapper">
        <div className="row rowcontainer">
          <div className="col">
            <div class="radio-form-container">
              {/* radio buttons  */}
              <div className="radio-btnwrapper">
                <form>
                  <select
                    class="custom-select selector"
                    id="inputGroupSelect04"
                    defaultValue={"ABSLI"}
                  >
                    <option
                      value="ABSLI"
                      onChange={() => {
                        setLob_code("basic");
                        setState((prev) => ({
                          ...prev,
                          payment_data: {
                            ...prev.payment_data,
                            payment_type: "BASIC",
                          },
                        }));
                        onChange("ABSLI", payment_methods);
                      }}
                    >
                      ABSLI
                    </option>
                  </select>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      id="basic-radio"
                      type="radio"
                      value="basic"
                      name="Form"
                      defaultChecked
                      onChange={() => {
                        setPayment_methods("basic");
                        onChange("ABSLI", "basic");
                      }}
                    />
                    Basic
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="initial"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("initial");
                        onChange(lob_code, "initial");
                      }}
                    />
                    Initial
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="initial_combo"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("initial_combo");
                        onChange(lob_code, "initial_combo");
                      }}
                    />
                    Initial Combo
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="initial_other"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("initial_other");
                        onChange(lob_code, "initial_other");
                      }}
                    />
                    Initial Other
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="renewal"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("renewal");
                        onChange(lob_code, "renewal");
                      }}
                    />
                    Renewal
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="renewal_combo"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("renewal_combo");
                        onChange(lob_code, "renewal_combo");
                      }}
                    />
                    Renewal Combo
                  </label>
                  <label class="radio-inline selector">
                    <input
                      className="radio-opt-button"
                      type="radio"
                      value="loan"
                      name="Form"
                      onChange={() => {
                        setPayment_methods("loan");
                        onChange(lob_code, "loan");
                      }}
                    />
                    Loan
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row rowcontainer">
          {/* form render in left side section  */}
          <div className="col-8">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="basic"
                  title="Basic"
                  onClick={() => setViewType("basic")}
                  className={viewType === "basic" ? "active" : ""}
                >
                  Basic
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="appdata"
                  title="AppData"
                  onClick={() => setViewType("appdata")}
                  className={viewType === "appdata" ? "active" : ""}
                >
                  AppData
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {viewType === "basic" ? (
              <div className="root-container">
                <Row>
                  {field &&
                    field.map((formelement, i) => {
                      if (formelement.parent_node === "payment_data") {
                        return (
                          <FormRender
                            edit={true}
                            key={i}
                            itm={{
                              name: formelement.attribute_key,
                              value:
                                state &&
                                state.payment_data &&
                                state.payment_data[formelement.attribute_key],
                              label: formelement.label,
                              placeholder: " ",
                              type: "floatingLabel",
                              row: i,
                              styleClass: "col-4",
                              edit: true,
                              handler: paymentDataHandler,
                            }}
                            formState={state}
                            setFormState={setState}
                          />
                        );
                      }
                      // });
                    })}
                </Row>
              </div>
            ) : (
              <div className="root-container">
                <AppData
                  mappingData={field}
                  state={state}
                  setState={setState}
                />
              </div>
            )}
          </div>

          {/* right section of row (response jason) */}
          <div className="col-4">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Request JSON
                </button>
              </h2>
              <div
                id="collapseOne"
                class={`accordion-collapse collapse ${!resp && "show"}`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body reqjsonAcc">
                  <div class="form-check form-switch">
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Redirect
                    </label>
                    <input
                      class="form-control form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      value="true"
                      checked={redirect}
                      onChange={() => {
                        setRedirect(!redirect);
                      }}
                    />
                  </div>
                  <div class="">
                    <textarea
                      id="reqresfields"
                      col="40"
                      row="18"
                      className="text_area"
                      value={JSON.stringify(state, undefined, 4)}
                    ></textarea>
                    <div class="footer">
                      <button
                        class="submit btn btn-md btn-success"
                        onClick={handleSubmit}
                      >
                        CREATE ORDER <i class="bi bi-arrow-right-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Response JSON
                </button>
              </h2>
              <div
                id="collapseTwo"
                class={`accordion-collapse collapse ${resp && "show"}`}
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="">
                    <textarea
                      id="reqresfields"
                      cols="40"
                      rows="18"
                      readonly=""
                      value={JSON.stringify(resp, undefined, 4)}
                    ></textarea>
                    <div class="footer">
                      <button class="btn btn-md text-primary">
                        <i class="bi-files"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StatusFooter /> */}
    </>
  );
};

export default TestPage;
