import React, { useEffect, useState } from "react";
import "./style.scss";
import ab_logo from "../../../assets/img/ab_logo.png";
import { Row, Col } from "react-bootstrap";
import { GetOrderDetail } from "../service/service";
import { useParams } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Header from "../../../common/header/Header";
import PaymentInProcess from "../../../common/StatusPages/PaymentInProcess";
import PageNotFound from "../../../common/StatusPages/PageNotFound";
import { CrossIcon, ErrorSymbol } from "../../../assets/img/svg";

const Transaction_Page2 = () => {
  let { id } = useParams();
  let [state, setState] = useState({});
  let [hide, setHide] = useState(true);
  let [load, setLoad] = useState(true);

  useEffect(() => {
    GetOrderDetail(id, state, setState, setLoad);
  }, []);
  return (
    <>
      <div className="page_wrapper">
        <div className="header-wrapper">
          <Header />
          <div className="header_bottom">
            <div className="header-bottom-contain">
              <div className="header_bottom_text">
                Aditya Birla Sun Life Insurance Company Limited
              </div>
            </div>
          </div>
        </div>

        {load ? (
          <div className="loaderdot">
            <div class="loading-dots">
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
              <div class="loading-dots--dot"></div>
            </div>
          </div>
        ) : (
          <>
            {state && state.success && (
              <div className="ver-com-contain">
                <div className="wrapper">
                  <div className="hor-top-contain-wrapper">
                    <div className="contain-heading">
                      <div className="welcometag">Welcome, </div>
                      <div className="customer-name">
                        {state &&
                          state.order_data &&
                          state.order_data.customer_name}
                      </div>
                    </div>
                    <div className="rightside">
                      {hide && state.message && (
                        <div className="error-msg-container">
                          <div className="error-symbol">
                            <ErrorSymbol />
                          </div>
                          <div className="error-message">
                            {state && state.message}
                          </div>
                          <button
                            className="error-close-btn"
                            onClick={() => {
                              setHide(false);
                            }}
                          >
                            <span className="cross-svg">
                              <CrossIcon />
                            </span>
                          </button>
                        </div>
                      )}
                      {state.expiry_time && (
                        <div className="timer">
                          <div className="circle-timer-container">
                            {/* state && (((Date.now()).getTime()/1000) - Math.floor(state.expiry_time/1000))} */}
                            <CountdownCircleTimer
                              className="circle-timer"
                              isPlaying
                              duration={
                                state &&
                                Math.floor(
                                  state.expiry_time -
                                    new Date().getTime() / 1000
                                )
                              }
                              colors={["#d9908a"]}
                              children={900}
                              onComplete={() => {}}
                            >
                              {({ remainingTime }) => {
                                const minutes = Math.floor(remainingTime / 60);
                                const seconds = remainingTime % 60;
                                return (
                                  <span className="circle-timer-time">
                                    {minutes}:{seconds}
                                    <div>Left</div>
                                  </span>
                                );
                              }}
                            </CountdownCircleTimer>
                          </div>
                          {state.attempt_left && (
                            <div className="attempt-count">
                              {state.attempt_left} attempt Left
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row_contain">
                    <Row>
                      <Col md={3}>
                        <div className="card v2-card">
                          <div className="ver-payment-gateway-header">
                            <div className="header_title">Summary</div>
                          </div>
                          <div className="summary-contain">
                            <Col>
                              <Row>
                                <Col xs={6} md={12}>
                                  <div className="summary-field">
                                    <p className="tableheader"> Name</p>
                                    <p className="tablecontent">
                                      {state &&
                                        state.order_data &&
                                        state.order_data.customer_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={6} md={12}>
                                  <div className="summary-field">
                                    <p className="tableheader">Email id</p>
                                    <p className="tablecontent">
                                      {state &&
                                        state.order_data &&
                                        state.order_data.customer_email}
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={6} md={12}>
                                  <div className="summary-field">
                                    <p className="tableheader">Mobile No.</p>
                                    <p className="tablecontent">
                                      {state &&
                                        state.order_data &&
                                        state.order_data.customer_phone}
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={6} md={12}>
                                  <div className="summary-field">
                                    {" "}
                                    <p className="tableheader">Amount</p>
                                    <p className="tablecontent">
                                      ₹
                                      {state &&
                                        state.order_data &&
                                        state.order_data.amount}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </div>
                        </div>
                      </Col>
                      <Col md={9}>
                        <div className="card ">
                          <div className="ver-payment-gateway-header">
                            <div className="header-left-section">
                              <div className="header_title">Pay</div>
                            </div>
                            <div className="header-right-section">
                              <div className="header_amount">
                                {" "}
                                {state &&
                                  state.order_data &&
                                  "₹" + state.order_data.amount}
                              </div>
                            </div>
                          </div>
                          {state && !state.inprogress ? ( // || state.attempt_data.status !== "FAILED" || state.attempt_data.status !== "SUCCESS") ?
                            <iframe
                              src={
                                state &&
                                state.attempt_data &&
                                state.attempt_data.pa_link_url
                              }
                              height="100%"
                              width="100%"
                              title="Iframe Example"
                            ></iframe>
                          ) : (
                            <PaymentInProcess />
                            // <>
                            //   {state && state.attempt_data && (
                            //     <>
                            //       <p>
                            //         Transaction is{" "}
                            //         {state &&
                            //           state.attempt_data &&
                            //           state.attempt_data.status}
                            //         .
                            //       </p>
                            //       <p>
                            //         TRANSACTION ID:{" "}
                            //         {state &&
                            //           state.attempt_data &&
                            //           state.attempt_data.pa_id}
                            //       </p>
                            //       <p>
                            //         STATUS:{" "}
                            //         {state &&
                            //           state.attempt_data &&
                            //           state.attempt_data.pa_status}
                            //       </p>
                            //     </>
                            //   )}
                            // </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {state && !state.success && state.error && <PageNotFound />}
          </>
        )}
      </div>
    </>
  );
};

export default Transaction_Page2;
