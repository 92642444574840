import React from "react";
import PageNotFound from "./PageNotFound";
import StatusFooter from "./StatusFooter";
import StatusHeader from "./StatusHeader";
import "./style.scss";

const StaticStatusPage = ({ error }) => {
  return (
    <>
      {error === "SUCCESS" && (
        <div className="updated-status">
          <h1 className="success-heading">Payment Successful</h1>
        </div>
      )}
      {error === "TIMEOUT" && (
        <div className="updated-status">
          <h1 className="timeOut-heading">Page timeout</h1>
        </div>
      )}
      {error === "INVALID" && (
        <div className="updated-status">
          <h1 className="inprogress-heading">
            {" "}
            <PageNotFound />
          </h1>
        </div>
      )}
      {error === "FAILED" && (
        <div className="updated-status">
          <h1 className="failed-heading">Payment failed</h1>
        </div>
      )}
      {error === "PGA_ERROR" && (
        <div className="updated-status">
          <h1 className="failed-heading">PAYMNET GATEWAY ERROR</h1>
        </div>
      )}
      {/* <StatusFooter /> */}
    </>
  );
};

export default StaticStatusPage;
