import Textarea from "rc-textarea";
import React from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "./FormRender.scss";
import { OnChangeHandler } from "./OnChangeHandler";
import dateFormat from "dateformat";

const FormRender = ({ itm, formState, setFormState, selectedItm, edit }) => {
  if ((edit === true && itm.edit) || (edit === false && true)) {
    switch (itm.type) {
      case "text":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Form.Control
                autocapitalize="word"
                className="form-control"
                type="text"
                name={itm.name}
                disabled={itm.disable}
                id={itm.name}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(e) =>
                  itm.handler
                    ? itm.handler(e, itm, null, formState, setFormState)
                    : OnChangeHandler(e, itm, null, formState, setFormState)
                }
              />
            </Form.Group>
          </Col>
        );
      case "date":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Flatpickr
                className="form-control"
                disabled={itm.disable}
                name={itm.name}
                id={itm.name}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(dates) => {
                  (itm.handler &&
                    itm.handler(dates, itm, null, formState, setFormState)) ||
                    OnChangeHandler(dates, itm, null, formState, setFormState);
                }}
                options={{
                  mode: "range",
                  minDate: "",
                  maxDate: "today",
                  dateFormat: "d-M-Y",
                }}
              />
            </Form.Group>
          </Col>
        );
      case "daterange":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              {/* <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label> */}
              <Flatpickr
                className="form-control datepickr"
                disabled={itm.disable}
                name={itm.name}
                id={itm.name}
                placeholder={itm.label || ""}
                options={{
                  mode: "range",
                  dateFormat: "d-M-Y",
                  onChange: (dates, [start, end]) => {
                    if (dates.length == 2) {
                      setFormState((prev) => ({
                        ...prev,
                        [itm.start]: dateFormat(dates[0], "dd-mm-yyyy"),
                        [itm.end]: dateFormat(dates[1], "dd-mm-yyyy"),
                      }));
                    }
                  },
                }}
              />
            </Form.Group>
          </Col>
        );
      case "email":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Form.Control
                className="form-control"
                type="email"
                name={itm.name}
                id={itm.name}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(e) => {
                  (itm.handler &&
                    itm.handler(e, itm, null, formState, setFormState)) ||
                    OnChangeHandler(e, itm, null, formState, setFormState);
                }}
              />
            </Form.Group>
          </Col>
        );
      case "textarea":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              {/* <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label> */}
              <FloatingLabel
                // controlId="floatingInput"
                label={itm.label}
                className="mb-3 filter-label"
              >
                <Textarea
                  maxlength="2000"
                  className="form-control"
                  type="textarea"
                  name={itm.name}
                  id={itm.name}
                  disabled={itm.disable}
                  placeholder={itm.label || ""}
                  value={itm.value || (formState && formState[itm.name])}
                  onChange={(e) => {
                    (itm.handler &&
                      itm.handler(e, itm, null, formState, setFormState)) ||
                      OnChangeHandler(e, itm, null, formState, setFormState);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        );
      case "json":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              {/* <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label> */}
              <Textarea
                maxlength="2000"
                className="form-control"
                type="textarea"
                name={itm.name}
                id={itm.name}
                disabled={itm.disable}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(e) => {
                  (itm.handler &&
                    itm.handler(e, itm, null, formState, setFormState)) ||
                    OnChangeHandler(e, itm, null, formState, setFormState);
                }}
              />
            </Form.Group>
          </Col>
        );
      case "number":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Form.Control
                className="form-control"
                type="number"
                name={itm.name}
                id={itm.name}
                disabled={itm.disable}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(e) =>
                  itm.handler
                    ? itm.handler(e, itm, null, formState, setFormState)
                    : OnChangeHandler(e, itm, null, formState, setFormState)
                }
              />
            </Form.Group>
          </Col>
        );
      case "multiselect":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group multi-select-form-group">
              {/* <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label> */}
              <Select
                className="multi-select-input-in-form"
                value={itm.value}
                placeholder={itm.label}
                options={itm.options}
                onChange={(data) => {
                  (itm.handler &&
                    itm.handler(null, itm, data, formState, setFormState)) ||
                    OnChangeHandler(null, itm, data, formState, setFormState);
                }}
                isMulti="true"
              />
            </Form.Group>
          </Col>
        );
      case "select":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group select-form-group">
              <div className="select-form-row">
                {/* <Form.Label htmlFor={itm.name} className="select-form-label">
                {itm.label}
              </Form.Label> */}
                <Select
                  className="select-input-in-form"
                  placeholder={itm.label}
                  floatingLabel="Works with selects"
                  options={itm.options}
                  isDisabled={itm.disable}
                  value={
                    itm.options.filter(
                      (itm1) =>
                        (itm1 && itm1.value && itm1.value.toString()) ===
                        (itm && itm.value && itm.value.toString())
                    )[0] ||
                    (formState && formState[itm.value])
                  }
                  onChange={(data) => {
                    (itm.handler &&
                      itm.handler(null, itm, data, formState, setFormState)) ||
                      OnChangeHandler(null, itm, data, formState, setFormState);
                  }}
                  required
                />
              </div>
            </Form.Group>
          </Col>
        );
      case "customselect":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Select
                options={itm.options}
                disabled={itm.disable}
                components={{ Option: itm.CustomOption }}
                value={
                  itm.options.filter(
                    (itm1) =>
                      (itm1 && itm1.value && itm1.value.toString()) ===
                      (itm && itm.value && itm.value.toString())
                  )[0] ||
                  (formState && formState[itm.value])
                }
                onChange={(data) => {
                  (itm.handler &&
                    itm.handler(null, itm, data, formState, setFormState)) ||
                    OnChangeHandler(null, itm, data, formState, setFormState);
                }}
              />
            </Form.Group>
          </Col>
        );
      case "switch":
        return (
          <Form.Group className={itm.styleClass}>
            <Form.Check
              type="switch"
              id={itm.name}
              className="imei-switch"
              label={itm.label}
              onChange={(e) => {
                (itm.handler &&
                  itm.handler(e, itm, null, formState, setFormState)) ||
                  OnChangeHandler(e, itm, null, formState, setFormState);
              }}
              checked={itm.value || ""}
            />
          </Form.Group>
        );
      case "password":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label htmlFor={itm.name}>{itm.label}</Form.Label>
              <Form.Control
                className="form-control"
                type="password"
                name={itm.name}
                id={itm.name}
                disabled={itm.disable}
                placeholder={itm.placeholder || ""}
                value={itm.value || (formState && formState[itm.name])}
                onChange={(e) => {
                  (itm.handler &&
                    itm.handler(e, itm, null, formState, setFormState)) ||
                    OnChangeHandler(e, itm, null, formState, setFormState);
                }}
              />
            </Form.Group>
          </Col>
        );
      case "display":
        return (
          <Col className={itm.styleClass}>
            <Form.Group className="form-group">
              <Form.Label>{itm.label}</Form.Label>
            </Form.Group>
          </Col>
        );
      case "daterangepicker":
        return <div className={itm.styleClass}></div>;

        case "floatingLabel":
        console.log(itm)
        return (
          <Col className={itm.styleClass}>
            <Form.Group
              className="mb-3 new-user-form-input"
              // controlId="formBasicEmail"
            >
              <FloatingLabel
                // controlId="floatingInput"
                label={itm.label}
                className="mb-3 filter-label"
              >
                <Form.Control
                  className="form-control"
                  type={itm.fieldtype?itm.fieldtype:"text"}
                  name={itm.name}
                  disabled={itm.disable}
                  id={itm.name}
                  placeholder={itm.placeholder || ""}
                  value={itm.value || (formState && formState[itm.name])}
                  onChange={(e) =>
                    itm.handler
                      ? itm.handler(e, itm, null, formState, setFormState)
                      : OnChangeHandler(e, itm, null, formState, setFormState)
                  }
                  required
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        );

      case "floatingLabel_pass":
        return (
          <Col className={itm.styleClass}>
            <Form.Group
              className="mb-3 new-user-form-input"
            >
              <FloatingLabel
                label={itm.label}
                className="mb-3 filter-label"
              >
                <Form.Control
                  className="form-control"
                  type="password"
                  name={itm.name}
                  disabled={itm.disable}
                  id={itm.name}
                  placeholder={itm.placeholder || ""}
                  value={itm.value || (formState && formState[itm.name])}
                  onChange={(e) =>
                    itm.handler
                      ? itm.handler(e, itm, null, formState, setFormState)
                      : OnChangeHandler(e, itm, null, formState, setFormState)
                  }
                  required
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        );
      
      case "span":
        return (
          <Col className={itm.styleClass}>
            <div xs={itm.styleClass}></div>
          </Col>
        );
      default:
        return (
          <Form.Group className="form-group">
            <Form.Label htmlFor={itm.name}>{itm.name}</Form.Label>
            <Form.Control
              className="form-control"
              type="text"
              name={itm.name}
              id={itm.name}
              onChange={(e) => {
                (itm.handler &&
                  itm.handler(e, itm, null, formState, setFormState)) ||
                  OnChangeHandler(e, itm, null, formState, setFormState);
              }}
            />
          </Form.Group>
        );
    }
  }
};

export default FormRender;
